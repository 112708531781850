<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>
            Deal Information

            <v-progress-circular indeterminate color="primary" />
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="status_submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="status_submitted = false"
        >mdi-close</v-icon
      >
      <div class="mr-10 text-subtitle-1">Successfully Updated</div>
    </v-alert>

    <v-alert
      v-model="warning"
      transition="fade-transition"
      type="warning"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="warning = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">{{ warningMessage }}</div>
      <v-divider class="mt-5" />
      <div class="d-flex alin-center justify-end mt-2">
        <v-btn
          @click="
            $router.push({ path: `/dashboard/admin/kanban/manage_stages/Deal` })
          "
          text
        >
          manage kanban
          <v-icon> mdi-gesture-tap </v-icon>
        </v-btn>
      </div>
    </v-alert>

    <v-alert
      v-model="payment_saved"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="payment_saved = false"
        >mdi-close</v-icon
      >
      <div class="mr-10 text-subtitle-1">Successfully Saved</div>
    </v-alert>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <v-row>
            <!-- Side Navigation -->
            <v-col cols="2">
              <div class="side-nav">
                SECTIONS:
                <v-list dense>
                  <!-- Navigation Links -->
                  <v-list-item
                    @click="
                      if (!panels.includes(0)) panels.push(0);
                      scrollToElement($refs.deal_info.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Deal Information </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(1)) panels.push(1);
                      scrollToElement($refs.objectives.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Objectives </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(2)) panels.push(2);
                      scrollToElement($refs.filogix.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Filogix </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(3)) panels.push(3);
                      scrollToElement($refs.customers.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Customers </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(4)) panels.push(4);
                      scrollToElement($refs.lawyers.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Lawyers </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(5)) panels.push(5);
                      scrollToElement($refs.deal_summaries.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Deal Summaries </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(6)) panels.push(6);
                      scrollToElement($refs.file.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Files </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(7)) panels.push(7);
                      scrollToElement($refs.funded_mortgage.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Funded Mortgage </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(8)) panels.push(8);
                      scrollToElement($refs.payment_activity.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Payment Activity </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      if (!panels.includes(9)) panels.push(9);
                      scrollToElement($refs.deal_note.$el);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Notes </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="panels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="green--text">
                        Expand All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="panels = []">
                    <v-list-item-content>
                      <v-list-item-title class="red--text">
                        Collapse All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>

            <v-col cols="10">
              <v-expansion-panels v-model="panels" multiple>
                <!-- Deal Information -->
                <v-expansion-panel ref="deal_info" class="bordered">
                  <v-expansion-panel-header>
                    <h3>
                      Deal Information

                      <v-icon
                        class="ml-4"
                        style="cursor: pointer"
                        @click="
                          $router.push({
                            path: `/dashboard/admin/deal/edit/${dealData.id}`,
                          })
                        "
                      >
                        mdi-pencil-outline
                      </v-icon>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <p class="sub-title-1">Deal Information</p>

                        <v-simple-table dense>
                          <thead>
                            <th :width="250" />
                          </thead>

                          <tbody>
                            <tr>
                              <td>Public Name</td>

                              <td>
                                {{ dealData.public_name }}
                              </td>
                            </tr>

                            <tr>
                              <td>Internal Name</td>

                              <td>
                                {{ dealData.internal_name }}
                              </td>
                            </tr>

                            <tr>
                              <td>Description</td>

                              <td>
                                {{ dealData.description }}
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Status</td>

                              <td>
                                {{ dealData.status }}
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Type</td>

                              <td>
                                {{ dealData.deal_type }}
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Purpose</td>

                              <td>
                                {{ dealData.deal_purpose }}
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Stage</td>

                              <td>
                                {{
                                  dealData.kbn_stg
                                    ? dealData.kbn_stg.stage_name
                                    : "Not Selected"
                                }}
                              </td>
                            </tr>

                            <tr v-if="dealData.renewal">
                              <td>Renewal</td>

                              <td>
                                {{ dealData.renewal ? "Yes" : "No" }}
                              </td>
                            </tr>

                            <tr v-if="dealData.parent_deal">
                              <td>Parent Deal</td>

                              <td>
                                <v-btn
                                  class="pa-0 text-body-2"
                                  text
                                  @click="
                                    $router.push({
                                      path: `/dashboard/admin/deal/read/${dealData.parent_deal.id}`,
                                    })
                                  "
                                >
                                  {{ dealData.parent_deal.public_name }}
                                </v-btn>
                              </td>
                            </tr>

                            <tr v-if="dealData.child_deal">
                              <td>Child Deal</td>

                              <td>
                                <v-btn
                                  class="pa-0 text-body-2"
                                  text
                                  @click="
                                    $router.push({
                                      path: `/dashboard/admin/deal/read/${dealData.child_deal.id}`,
                                    })
                                  "
                                >
                                  {{ dealData.child_deal.public_name }}
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <v-expansion-panel class="mt-2 bordered">
                          <v-expansion-panel-header>
                            Links
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <p
                              v-if="dealData.links.length > 0"
                              class="grey--text"
                            >
                              Click on the link to open it in a new tab.
                            </p>

                            <v-list class="pa-0">
                              <v-list-item
                                v-for="link in dealData.links"
                                :key="link.id"
                                class="d-flex align-center"
                                link
                              >
                                <v-list-item-content>
                                  <a
                                    :href="
                                      link.url.startsWith('http://') ||
                                      link.url.startsWith('https://')
                                        ? link.url
                                        : 'http://' + link.url
                                    "
                                    target="_blank"
                                  >
                                    {{ link.description }}
                                  </a>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <div class="d-flex align-center">
                                    <v-btn
                                      icon
                                      @click="edit_link(link)"
                                      color="teal"
                                      title="Edit Link"
                                    >
                                      <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                      icon
                                      @click="delete_link(link.id)"
                                      color="red"
                                      title="Delete Link"
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </div>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>

                            <div class="d-flex justify-end mt-5">
                              <v-btn
                                @click="dialog_link = true"
                                color="teal"
                                text
                              >
                                + Add New Link
                              </v-btn>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- add new link dialog -->
                        <v-dialog
                          v-model="dialog_link"
                          max-width="500px"
                          persistent
                        >
                          <v-toolbar color="orange darken-3">
                            <v-toolbar-title flat>
                              {{ is_edit_link ? "Edit" : "Add New" }} Link
                            </v-toolbar-title>
                            <v-spacer />
                            <v-btn
                              icon
                              @click="
                                dialog_link = false;
                                is_edit_link = false;
                                $refs.link_form.resetForm();
                              "
                              title="Click to close"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card>
                            <v-card-text>
                              <AddLinkForm
                                class="pt-10"
                                ref="link_form"
                                @add-link="submit_link"
                                @close="
                                  dialog_link = false;
                                  is_edit_link = false;
                                  $refs.link_form.resetForm();
                                "
                              />
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>

                    <v-divider class="my-4" />
                    <v-row>
                      <v-col>
                        <p class="sub-title-1 mb-0">Quick Updates</p>
                      </v-col>
                    </v-row>

                    <v-form ref="quickUpdate">
                      <v-alert
                        class="mt-5"
                        v-show="
                          deal_status !== dealData.status
                            ? true
                            : new_broker_fee_value !==
                              $options.filters.formatPrice(
                                String(dealData.broker_fee_value)
                              )
                            ? true
                            : new_broker_fee_value_chance !==
                              dealData.broker_fee_value_chance
                            ? true
                            : false
                        "
                        color="orange darken-3"
                        dense
                        transition="fade-transition"
                      >
                        <div>
                          <v-icon>mdi-information-outline</v-icon>
                          <span class="ml-2">
                            The following changes will only be applied to the
                            deal after clicking the update button.
                          </span>
                        </div>
                      </v-alert>
                      <!-- broker fee value -->
                      <v-row>
                        <v-col>
                          <p class="caption mb-0">Broker Fee Value</p>

                          <v-text-field
                            v-model="new_broker_fee_value"
                            hide-details="auto"
                            @blur="
                              new_broker_fee_value =
                                $options.filters.formatPrice(
                                  new_broker_fee_value
                                )
                            "
                            @change="handle_estimated_value"
                            outlined
                            dense
                            flat
                          />
                        </v-col>

                        <v-col>
                          <p class="caption mb-0">Broker Fee Value Chance</p>

                          <v-text-field
                            v-model="new_broker_fee_value_chance"
                            hide-details="auto"
                            :rules="[...formRules.number, ...formRules.max100]"
                            @change="handle_estimated_value"
                            append-icon="mdi-percent"
                            outlined
                            dense
                            flat
                          />
                        </v-col>

                        <v-col>
                          <p class="caption mb-0">Estimated Value</p>

                          <v-text-field
                            v-model="new_estimated_value"
                            hide-details="auto"
                            outlined
                            disabled
                            dense
                          />
                        </v-col>
                      </v-row>

                      <!-- deal status -->
                      <v-row>
                        <v-col>
                          <p class="caption mb-0">Deal Status</p>
                          <v-select
                            v-model="new_deal_status"
                            label="Select Status"
                            hide-details="auto"
                            :items="status_options"
                            :rules="formRules.required"
                            @change="
                              new_paid_out_type = null;
                              new_funded_type = null;
                              new_closed_type = null;
                            "
                            solo
                            dense
                            flat
                          />
                        </v-col>

                        <v-col v-if="new_deal_status == 'Funded'">
                          <p class="caption mb-0">Funded Type</p>
                          <v-select
                            label="Select Funded Type (Required)"
                            hide-details="auto"
                            v-model="new_funded_type"
                            :items="new_funded_type_options"
                            :rules="formRules.required"
                            solo
                            dense
                            flat
                          />
                        </v-col>

                        <v-col v-if="new_deal_status == 'Closed'">
                          <p class="caption mb-0">Closed Type</p>
                          <v-select
                            label="Select Closed Type (Required)"
                            hide-details="auto"
                            v-model="new_closed_type"
                            :items="new_closed_type_options"
                            :rules="formRules.required"
                            @change="new_paid_out_type = null"
                            solo
                            dense
                            flat
                          />
                        </v-col>

                        <v-col
                          v-if="
                            new_deal_status != 'Funded' &&
                            new_deal_status !== 'Closed'
                          "
                        />

                        <v-col v-if="new_closed_type != 'Paid Out'" />

                        <v-col
                          v-if="
                            new_closed_type == 'Paid Out' &&
                            new_deal_status == 'Closed'
                          "
                        >
                          <p class="caption mb-0">Paid Out Type</p>
                          <v-select
                            label="Select Paid Out Type (Required)"
                            hide-details="auto"
                            v-model="new_paid_out_type"
                            :rules="formRules.required"
                            :items="new_paid_out_type_options"
                            solo
                            dense
                            flat
                          />
                        </v-col>
                      </v-row>

                      <!-- action -->
                      <v-row>
                        <v-col>
                          <v-btn
                            @click="handle_quick_update_btn"
                            :color="
                              deal_status !== dealData.status
                                ? 'primary'
                                : new_broker_fee_value !==
                                  $options.filters.formatPrice(
                                    String(dealData.broker_fee_value)
                                  )
                                ? 'primary'
                                : new_broker_fee_value_chance !==
                                  dealData.broker_fee_value_chance
                                ? 'primary'
                                : ''
                            "
                          >
                            update
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>

                    <v-divider class="my-4" />

                    <v-row>
                      <v-col>
                        <v-btn @click="change_deal_to_draft">
                          Change to Draft
                        </v-btn>

                        &nbsp;<v-btn @click="archive_deal">Archive Deal</v-btn>

                        &nbsp;<v-btn
                          v-if="
                            dealData.parent_deal === null &&
                            dealData.child_deal === null
                          "
                          @click="
                            $router.push({
                              path: `/dashboard/admin/deal/renew/${dealData.id}`,
                            })
                          "
                        >
                          Renew Deal
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Objectives / Service Ticket -->
                <v-expansion-panel ref="objectives" class="bordered">
                  <v-expansion-panel-header>
                    <h3>Objectives</h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <ObjectiveList
                      v-if="dealData.stg_itms.length > 0"
                      :stage_item_id="
                        dealData.stg_itms.find(
                          (el) => el.kbn_stg.id === dealData.kbn_stg.id
                        ).id
                      "
                      :stg_itm="
                        dealData.stg_itms.find(
                          (el) => el.kbn_stg.id === dealData.kbn_stg.id
                        )
                      "
                      kanban_type="Deal"
                      view_type="deal"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Filogix -->
                <v-expansion-panel ref="filogix" class="bordered">
                  <v-expansion-panel-header>
                    <h3>Filogix</h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-dialog
                      v-model="dialog_filogix"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                    >
                      <v-card>
                        <v-toolbar flat dark color="teal">
                          <v-btn icon dark @click="dialog_filogix = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-toolbar-title>Post to Filogix</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="text-center">
                          <h1 class="mt-16">this page is under dev</h1>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <v-btn @click="dialog_filogix = true" class="my-5">
                      Post to filogix
                    </v-btn>
                    <br />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Customers -->
                <v-expansion-panel ref="customers" class="bordered">
                  <v-expansion-panel-header>
                    <h3>
                      Customers
                      <v-icon class="ml-4">mdi-account-group-outline</v-icon>

                      <v-btn
                        icon
                        title="Sync Latest Data"
                        v-if="
                          dealData.int_custs.length != 0 &&
                          dealData.status === 'In Progress'
                        "
                        @click.native.stop="sync_customers"
                        class="ml-2"
                      >
                        <v-icon> mdi-sync </v-icon>
                      </v-btn>

                      <span
                        v-if="dealData.int_custs.length == 0"
                        class="red--text text-body-2"
                      >
                        (please add customers)
                      </span>

                      <span
                        v-if="there_is_no_main_applicant"
                        class="red--text text-body-2"
                      >
                        (at least one main applicant is required, kindly select
                        one below)
                      </span>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div v-if="loading_int_cust">
                      <h3 class="text-center grey--text">
                        <v-progress-circular indeterminate color="primary" />
                        Loading Customer Data
                      </h3>
                    </div>

                    <v-expansion-panels
                      v-if="dealData.int_custs.length != 0 && !loading_int_cust"
                      multiple
                    >
                      <v-expansion-panel
                        v-for="int_cust in dealData.int_custs.filter(
                          (el) => el.customer
                        )"
                        :key="int_cust.id"
                        class="bordered"
                      >
                        <v-expansion-panel-header>
                          <div class="d-flex align-center">
                            <v-checkbox
                              v-model="int_cust.main_applicant"
                              class="mt-0"
                              hide-details="auto"
                              :disabled="dealData.status !== 'In Progress'"
                              @change="
                                toggle_int_cust_main_applicant(
                                  int_cust.id,
                                  int_cust.main_applicant
                                )
                              "
                            />
                            {{ int_cust.customer.salutaion }}
                            {{ int_cust.customer.first_name }}
                            {{ int_cust.customer.middle_name }}
                            {{ int_cust.customer.last_name }}
                            {{
                              int_cust.main_applicant ? " (Main Applicant)" : ""
                            }}
                          </div>

                          <div class="d-flex justify-end">
                            <v-btn
                              v-if="dealData.status === 'In Progress'"
                              text
                              title="remove customer"
                              color="red"
                              @click="handle_unassociate_customer(int_cust.id)"
                            >
                              remove
                            </v-btn>
                          </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <v-simple-table>
                            <thead>
                              <th :width="250" />
                              <th />
                            </thead>

                            <tbody>
                              <tr>
                                <td colspan="2" class="grey--text">
                                  Contact Information:
                                </td>
                              </tr>

                              <tr>
                                <td>Email</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in int_cust.customer.contact_infos.filter(
                                      (el) => el.type === 'Email'
                                    )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.email }}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Home Phone Number</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in int_cust.customer.contact_infos.filter(
                                      (el) => el.type === 'Home Phone Number'
                                    )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.number }}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Cellphone Number</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in int_cust.customer.contact_infos.filter(
                                      (el) => el.type === 'Cell Phone Number'
                                    )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.number }}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Work Phone Number</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in int_cust.customer.contact_infos.filter(
                                      (el) => el.type === 'Work Phone Number'
                                    )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.number }}
                                  </div>
                                </td>
                              </tr>

                              <tr v-if="!int_cust.main_applicant">
                                <td colspan="2" class="grey--text">
                                  Co-Applicant Information:
                                </td>
                              </tr>

                              <tr v-if="!int_cust.main_applicant">
                                <td>Co-Applicant Type</td>

                                <td>
                                  {{ int_cust.co_applicant_type }}
                                </td>
                              </tr>

                              <tr
                                v-if="
                                  int_cust.business_for_self ||
                                  int_cust.variable_income ||
                                  int_cust.poa
                                "
                              >
                                <td colspan="2" class="grey--text">
                                  Financial Information:
                                </td>
                              </tr>

                              <tr v-if="int_cust.business_for_self">
                                <td>
                                  Bussiness for Self:
                                  {{ int_cust.business_for_self | yes_no }}
                                </td>

                                <td>
                                  <v-expansion-panel class="my-2 bordered">
                                    <v-expansion-panel-header>
                                      Bussiness for Self Notes
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content class="mt-5">
                                      <p
                                        v-for="item in int_cust.business_self_notes"
                                        :key="item.id"
                                      >
                                        {{ item.note }}
                                        <span
                                          v-if="
                                            item.cust_emp_business_self
                                              .employer_name !== null
                                          "
                                          class="grey--text"
                                        >
                                          - Employer:
                                          {{
                                            item.cust_emp_business_self
                                              .employer_name
                                          }}
                                        </span>
                                      </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </td>
                              </tr>

                              <tr v-if="int_cust.variable_income">
                                <td>
                                  Variable Income:
                                  {{ int_cust.variable_income | yes_no }}
                                </td>

                                <td>
                                  <v-expansion-panel class="my-2 bordered">
                                    <v-expansion-panel-header>
                                      Variable Income Notes
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content class="mt-5">
                                      <p
                                        v-for="item in int_cust.var_inc_notes"
                                        :key="item.id"
                                      >
                                        {{ item.note }}

                                        <span
                                          v-if="
                                            item.cust_emp_var_inc
                                              .employer_name !== null
                                          "
                                          class="grey--text"
                                        >
                                          - Employer:
                                          {{
                                            item.cust_emp_var_inc.employer_name
                                          }}
                                        </span>
                                      </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </td>
                              </tr>

                              <tr v-if="int_cust.poa">
                                <td>
                                  Power of Attorney:
                                  {{ int_cust.poa | yes_no }}
                                </td>

                                <td>
                                  <v-expansion-panel class="my-2 bordered">
                                    <v-expansion-panel-header>
                                      Power of Attorney Notes
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content class="mt-5">
                                      <p
                                        v-for="item in int_cust.poa_notes"
                                        :key="item.id"
                                      >
                                        • {{ item.note }}
                                      </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>

                          <div class="mt-5">
                            <v-btn
                              @click="
                                $router.push({
                                  path: `/dashboard/admin/customer/update_from_deal?deal_id=${dealData.id}&customer_id=${int_cust.customer.id}&int_cust_id=${int_cust.id}`,
                                })
                              "
                              v-if="dealData.status === 'In Progress'"
                              class="mr-2"
                            >
                              Update
                            </v-btn>

                            <v-btn
                              @click="
                                $router.push({
                                  path: `/dashboard/admin/deal/${deal_id}/customer/${int_cust.customer.id}/read`,
                                })
                              "
                            >
                              more details
                              <v-icon class="ml-2">
                                mdi-information-variant-circle-outline
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-btn
                      v-if="dealData.status === 'In Progress'"
                      class="my-5"
                      @click="
                        $router.push({
                          path: `/dashboard/admin/customer/create_from_deal?deal_id=${dealData.id}`,
                        })
                      "
                    >
                      Add New
                    </v-btn>

                    <v-btn
                      v-if="dealData.status === 'In Progress'"
                      class="my-5 ml-2"
                      @click="adding_existing_customer = true"
                    >
                      Add Existing
                    </v-btn>

                    <br />

                    <AddExistingCustomer
                      v-if="adding_existing_customer"
                      type="associate_to_deal"
                      :customer_suggestions="customer_suggestions"
                      :associated_customers="dealData.int_custs"
                      :unassociated_customers="unassociated_customers"
                      @close="adding_existing_customer = false"
                      @associate_customer="associate_customer"
                    />

                    <div
                      v-show="
                        dealData.int_custs.length > 0 && !loading_int_cust
                      "
                    >
                      <v-expansion-panels>
                        <v-expansion-panel class="mt-5 bordered">
                          <v-expansion-panel-header>
                            Properties
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <p
                              v-if="dealData.int_props.length == 0"
                              class="d-flex justify-center grey--text text-subtitle-1"
                            >
                              No properties were found. Kindly add properties to
                              customer.
                            </p>

                            <v-simple-table
                              v-if="dealData.int_props.length > 0"
                            >
                              <thead class="text-caption">
                                <tr>
                                  <th :width="80">Subject Property?</th>
                                  <th>Owned By</th>
                                  <th>Address</th>
                                  <th>Type</th>
                                  <th>Purchase Price</th>
                                  <th>Purchase Date</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="item in dealData.int_props.filter(
                                    (el) => {
                                      if (el.prop) {
                                        return dealData.int_custs.some(
                                          (int_cust) => {
                                            if (int_cust.customer) {
                                              return int_cust.customer.props.some(
                                                (prop) => prop.id === el.prop.id
                                              );
                                            }
                                          }
                                        );
                                      } else {
                                        return false;
                                      }
                                    }
                                  )"
                                  :key="item.id"
                                >
                                  <td>
                                    <v-checkbox
                                      class="mt-0 pt-0"
                                      v-model="item.subject"
                                      @change="
                                        toggle_int_prop_subject(
                                          item.id,
                                          item.subject
                                        )
                                      "
                                      :disabled="
                                        dealData.status !== 'In Progress'
                                      "
                                      hide-details
                                    />
                                  </td>

                                  <td>
                                    <ul v-if="item.prop.customers.length !== 0">
                                      <li
                                        v-for="(customer, index) in item.prop
                                          .customers"
                                        :key="customer.id"
                                      >
                                        {{ customer.first_name }}
                                        <span
                                          v-if="
                                            item.prop.customers.length > 0 &&
                                            item.prop.customers.length !==
                                              index + 1
                                          "
                                        >
                                          &
                                        </span>
                                      </li>
                                    </ul>

                                    <span v-else>
                                      customer not properly associated to this
                                      property
                                    </span>
                                  </td>

                                  <td>
                                    <span v-if="item.prop.address">
                                      {{ item.prop.address.street_number }},
                                      {{ item.prop.address.street_name + "," }},
                                      {{ item.prop.address.street_type + "," }},
                                      {{
                                        item.prop.address.street_direction +
                                        ","
                                      }}, {{ item.prop.address.unit + "," }},
                                      {{ item.prop.address.postal_code }},
                                      {{ item.prop.address.city + "," }},
                                      {{ item.prop.address.province }}
                                    </span>

                                    <span v-else>
                                      address not properly associated to this
                                      property
                                    </span>
                                  </td>

                                  <td>
                                    <span v-if="item.prop.prop_style">
                                      {{ item.prop.prop_style.property_type }}
                                    </span>

                                    <span v-else>
                                      property type not properly associated to
                                      this property
                                    </span>
                                  </td>

                                  <td>
                                    {{ item.prop.purchase_price | currency }}
                                  </td>

                                  <td>
                                    {{ item.prop.purchase_date | formatDate }}
                                  </td>

                                  <td>
                                    <v-btn
                                      icon
                                      title="Edit Property"
                                      @click="
                                        $router.push(
                                          `/dashboard/admin/property/update_from_deal?deal_id=${dealData.id}&property_id=${item.prop.id}`
                                        )
                                      "
                                    >
                                      <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Lawyers -->
                <v-expansion-panel ref="lawyers" class="bordered">
                  <v-expansion-panel-header>
                    <h3>
                      Lawyers
                      <span
                        v-if="
                          borrower_lyrs.length === 0 && lender_lyrs.length === 0
                        "
                      >
                        ( Please Add Lawyers )
                      </span>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-expansion-panels multiple>
                      <!-- BORROWER LAWYERS -->
                      <v-expansion-panel class="bordered">
                        <v-expansion-panel-header>
                          Borrowers
                        </v-expansion-panel-header>

                        <v-divider />

                        <v-expansion-panel-content>
                          <h3
                            v-if="
                              borrower_lyrs.filter((el) => !el.archived)
                                .length === 0
                            "
                            class="mt-10 grey--text text-center"
                          >
                            No Lawyers For The Borrower
                          </h3>

                          <v-expansion-panels class="mt-5" multiple>
                            <v-expansion-panel
                              class="bordered mb-4"
                              v-for="(
                                borrower_lyr, index
                              ) in borrower_lyrs.filter((el) => !el.archived)"
                              :key="borrower_lyr.id"
                            >
                              <v-expansion-panel-header>
                                <div class="d-flex align-center">
                                  {{
                                    borrower_lyr.user.companies
                                      .map((company) => company.name)
                                      .join(", ")
                                  }}
                                  -
                                  {{ borrower_lyr.user.first_name }}
                                  {{ borrower_lyr.user.last_name }}
                                  {{ borrower_lyr.active ? " (Active)" : "" }}

                                  <span class="ml-auto">
                                    <v-btn
                                      v-if="dealData.status === 'In Progress'"
                                      text
                                      color="red"
                                      @click.native.stop="
                                        handle_remove_lawyer(
                                          borrower_lyr.id,
                                          'borrower'
                                        )
                                      "
                                    >
                                      Remove
                                    </v-btn>
                                  </span>
                                </div>
                              </v-expansion-panel-header>

                              <v-divider />

                              <v-expansion-panel-content>
                                <p class="grey--text mt-5">Contact Info:</p>
                                <v-simple-table dense>
                                  <tbody>
                                    <tr>
                                      <td>Primary Email</td>

                                      <td>
                                        {{ borrower_lyr.user.email }}
                                      </td>
                                    </tr>

                                    <tr
                                      v-for="contact_info in borrower_lyr.user
                                        .contact_infos"
                                      :key="contact_info.id"
                                    >
                                      <td>{{ contact_info.type }}</td>

                                      <td>
                                        {{
                                          contact_info.type !== "Email"
                                            ? contact_info.number
                                            : contact_info.email
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>

                                <p class="grey--text mt-5">Notes:</p>

                                <v-data-table
                                  v-model="borrower_lyr.selected_notes"
                                  :headers="lawyer_note_headers"
                                  :items="borrower_lyr.notes"
                                  item-key="id"
                                  dense
                                  :show-select="
                                    dealData.status === 'In Progress'
                                  "
                                  @click:row="
                                    handle_update_lawyer_note(
                                      $event,
                                      index,
                                      'borrower'
                                    )
                                  "
                                />

                                <v-btn
                                  v-if="borrower_lyr.selected_notes.length > 0"
                                  color="red"
                                  @click="
                                    handle_delete_lawyer_note(
                                      borrower_lyr.id,
                                      'borrower'
                                    )
                                  "
                                >
                                  delete
                                </v-btn>

                                <!-- borrower lawyer note action -->
                                <div class="mt-5">
                                  <v-btn
                                    v-if="
                                      !borrower_lyr.adding_note &&
                                      !borrower_lyr.updating_note
                                    "
                                    @click="borrower_lyr.adding_note = true"
                                  >
                                    Add Note
                                  </v-btn>

                                  <v-btn
                                    v-if="
                                      !borrower_lyr.adding_note &&
                                      !borrower_lyr.updating_note
                                    "
                                    class="ml-5"
                                    @click="
                                      handle_archive_lawyer(
                                        borrower_lyr.id,
                                        'borrower'
                                      )
                                    "
                                  >
                                    Archive
                                  </v-btn>
                                </div>

                                <!-- add/update borrower lawyer note -->
                                <v-card
                                  class="mt-5"
                                  v-if="
                                    borrower_lyr.adding_note ||
                                    borrower_lyr.updating_note
                                  "
                                >
                                  <v-card-title class="text-body-2">
                                    {{
                                      borrower_lyr.adding_note
                                        ? "Add"
                                        : "Update"
                                    }}
                                    Note
                                  </v-card-title>

                                  <v-card-text>
                                    <v-textarea
                                      v-model="new_borrower_lawyer_note"
                                      label="Make a note"
                                      auto-grow
                                      outlined
                                    />
                                  </v-card-text>

                                  <v-divider />

                                  <v-card-actions>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        borrower_lyr.adding_note
                                          ? handle_save_lyr_note(
                                              borrower_lyr.id,
                                              'borrower'
                                            )
                                          : handle_save_updated_lyr_note(
                                              borrower_lyr.id,
                                              'borrower'
                                            )
                                      "
                                    >
                                      save
                                    </v-btn>

                                    <v-btn
                                      text
                                      @click="
                                        borrower_lyr.adding_note = false;
                                        borrower_lyr.updating_note = false;
                                      "
                                    >
                                      cancel
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <!-- archived borrower lawyers -->
                          <v-expansion-panel
                            class="mt-5 bordered"
                            v-if="borrower_lyrs.some((el) => el.archived)"
                          >
                            <v-expansion-panel-header>
                              Archived Lawyers ({{
                                borrower_lyrs.filter((el) => el.archived)
                                  .length
                              }})
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <h3
                                v-if="
                                  borrower_lyrs.filter((el) => el.archived)
                                    .length === 0
                                "
                                class="mt-5 grey--text text-center"
                              >
                                No Archived Lawyers
                              </h3>

                              <v-expansion-panels class="mt-5" multiple>
                                <v-expansion-panel
                                  class="bordered mb-4"
                                  v-for="borrower_lyr in borrower_lyrs.filter(
                                    (el) => el.archived
                                  )"
                                  :key="borrower_lyr.id"
                                >
                                  <v-expansion-panel-header>
                                    <div class="d-flex align-center">
                                      {{
                                        borrower_lyr.user.companies
                                          .map((company) => company.name)
                                          .join(", ")
                                      }}
                                      -
                                      {{ borrower_lyr.user.first_name }}
                                      {{ borrower_lyr.user.last_name }}
                                      {{
                                        borrower_lyr.active ? " (Active)" : ""
                                      }}
                                    </div>
                                  </v-expansion-panel-header>

                                  <v-divider />

                                  <v-expansion-panel-content>
                                    <v-simple-table class="mt-5" dense>
                                      <tbody>
                                        <tr>
                                          <td>Primary Email</td>

                                          <td>
                                            {{ borrower_lyr.user.email }}
                                          </td>
                                        </tr>

                                        <tr
                                          v-for="contact_info in borrower_lyr
                                            .user.contact_infos"
                                          :key="contact_info.id"
                                        >
                                          <td>{{ contact_info.type }}</td>

                                          <td>
                                            {{
                                              contact_info.type !== "Email"
                                                ? contact_info.number
                                                : contact_info.email
                                            }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>

                                    <!-- list of notes -->

                                    <v-data-table
                                      class="mt-5"
                                      :headers="lawyer_note_headers"
                                      :items="borrower_lyr.notes"
                                      :items-per-page="5"
                                      dense
                                    />

                                    <div>
                                      <v-btn
                                        class="mt-5"
                                        @click="
                                          handle_archive_lawyer(
                                            borrower_lyr.id,
                                            'borrower'
                                          )
                                        "
                                      >
                                        unarchive
                                      </v-btn>
                                    </div>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <!-- borrower lawyer action -->
                          <div class="mt-5">
                            <v-btn
                              v-if="!adding_borrower_lawyer"
                              @click="adding_borrower_lawyer = true"
                            >
                              Add Lawyer
                            </v-btn>
                          </div>

                          <!-- add borrwer lawyer -->
                          <div v-if="adding_borrower_lawyer">
                            <v-text-field
                              v-model="search_borrower_lawyers"
                              label="Search"
                              single-line
                              @input="
                                search_borrower_lawyers =
                                  search_borrower_lawyers.toLowerCase()
                              "
                              hide-details
                            />

                            <v-data-table
                              :headers="lawyer_headers"
                              :items="
                                company_lawyers.filter((el) => {
                                  return (
                                    !lender_lyrs
                                      .map((el) => el.user.id)
                                      .includes(el.id) &&
                                    !borrower_lyrs
                                      .map((el) => el.user.id)
                                      .includes(el.id)
                                  );
                                })
                              "
                              :items-per-page="5"
                              :search="search_borrower_lawyers"
                              :custom-filter="customFilter"
                              @click:row="handle_add_lawyer($event)"
                            >
                              <template v-slot:item.companies="{ item }">
                                {{
                                  item.companies
                                    .map((company) => company.name)
                                    .join(", ")
                                }}
                              </template>
                            </v-data-table>

                            <v-btn
                              class="mt-5"
                              @click="adding_borrower_lawyer = false"
                            >
                              cancel
                            </v-btn>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <!-- LENDER LAWYERS -->
                      <v-expansion-panel class="bordered">
                        <v-expansion-panel-header>
                          Lender
                        </v-expansion-panel-header>

                        <v-divider />

                        <v-expansion-panel-content>
                          <h3
                            v-if="
                              lender_lyrs.filter((el) => !el.archived)
                                .length === 0
                            "
                            class="mt-10 grey--text text-center"
                          >
                            No Lawyers For The Lender
                          </h3>

                          <v-expansion-panels class="mt-5" multiple>
                            <v-expansion-panel
                              class="bordered mb-4"
                              v-for="(lender_lyr, index) in lender_lyrs.filter(
                                (el) => !el.archived
                              )"
                              :key="lender_lyr.id"
                            >
                              <v-expansion-panel-header>
                                <div class="d-flex align-center">
                                  {{
                                    lender_lyr.user.companies
                                      .map((company) => company.name)
                                      .join(", ")
                                  }}
                                  -
                                  {{ lender_lyr.user.first_name }}
                                  {{ lender_lyr.user.last_name }}
                                  {{ lender_lyr.active ? " (Active)" : "" }}

                                  <span class="ml-auto">
                                    <v-btn
                                      v-if="dealData.status === 'In Progress'"
                                      text
                                      color="red"
                                      @click.native.stop="
                                        handle_remove_lawyer(
                                          lender_lyr.id,
                                          'lender'
                                        )
                                      "
                                    >
                                      Remove
                                    </v-btn>
                                  </span>
                                </div>
                              </v-expansion-panel-header>

                              <v-divider />

                              <v-expansion-panel-content>
                                <p class="grey--text mt-5">Contact Info:</p>
                                <v-simple-table dense>
                                  <tbody>
                                    <tr>
                                      <td>Primary Email</td>

                                      <td>
                                        {{ lender_lyr.user.email }}
                                      </td>
                                    </tr>

                                    <tr
                                      v-for="contact_info in lender_lyr.user
                                        .contact_infos"
                                      :key="contact_info.id"
                                    >
                                      <td>{{ contact_info.type }}</td>

                                      <td>
                                        {{
                                          contact_info.type !== "Email"
                                            ? contact_info.number
                                            : contact_info.email
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>

                                <p class="grey--text mt-5">Notes:</p>

                                <v-data-table
                                  v-model="lender_lyr.selected_notes"
                                  :headers="lawyer_note_headers"
                                  :items="lender_lyr.notes"
                                  item-key="id"
                                  dense
                                  :show-select="
                                    dealData.status === 'In Progress'
                                  "
                                  @click:row="
                                    handle_update_lawyer_note(
                                      $event,
                                      index,
                                      'lender'
                                    )
                                  "
                                />

                                <v-btn
                                  v-if="lender_lyr.selected_notes.length > 0"
                                  color="red"
                                  @click="
                                    handle_delete_lawyer_note(
                                      lender_lyr.id,
                                      'lender'
                                    )
                                  "
                                >
                                  delete
                                </v-btn>

                                <!-- lender lawyer note action -->
                                <div class="mt-5">
                                  <v-btn
                                    v-if="
                                      !lender_lyr.adding_note &&
                                      !lender_lyr.updating_note
                                    "
                                    @click="lender_lyr.adding_note = true"
                                  >
                                    Add Note
                                  </v-btn>

                                  <v-btn
                                    v-if="
                                      !lender_lyr.adding_note &&
                                      !lender_lyr.updating_note
                                    "
                                    class="ml-5"
                                    @click="
                                      handle_archive_lawyer(
                                        lender_lyr.id,
                                        'lender'
                                      )
                                    "
                                  >
                                    Archive
                                  </v-btn>
                                </div>

                                <!-- add/update lender lawyer note -->
                                <v-card
                                  class="mt-5"
                                  v-if="
                                    lender_lyr.adding_note ||
                                    lender_lyr.updating_note
                                  "
                                >
                                  <v-card-title class="text-body-2">
                                    {{
                                      lender_lyr.adding_note ? "Add" : "Update"
                                    }}
                                    Note
                                  </v-card-title>

                                  <v-card-text>
                                    <v-textarea
                                      v-model="new_lender_lawyer_note"
                                      label="Make a note"
                                      auto-grow
                                      outlined
                                    />
                                  </v-card-text>

                                  <v-divider />

                                  <v-card-actions>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        lender_lyr.adding_note
                                          ? handle_save_lyr_note(
                                              lender_lyr.id,
                                              'lender'
                                            )
                                          : handle_save_updated_lyr_note(
                                              lender_lyr.id,
                                              'lender'
                                            )
                                      "
                                    >
                                      save
                                    </v-btn>

                                    <v-btn
                                      text
                                      @click="
                                        lender_lyr.adding_note = false;
                                        lender_lyr.updating_note = false;
                                      "
                                    >
                                      cancel
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <!-- archived lender lawyers -->
                          <v-expansion-panel
                            class="mt-5 bordered"
                            v-if="lender_lyrs.some((el) => el.archived)"
                          >
                            <v-expansion-panel-header>
                              Archived Lawyers ({{
                                lender_lyrs.filter((el) => el.archived).length
                              }})
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <h3
                                v-if="
                                  lender_lyrs.filter((el) => el.archived)
                                    .length === 0
                                "
                                class="mt-5 grey--text text-center"
                              >
                                No Archived Lawyers
                              </h3>

                              <v-expansion-panels class="mt-5" multiple>
                                <v-expansion-panel
                                  class="bordered"
                                  v-for="lender_lyr in lender_lyrs.filter(
                                    (el) => el.archived
                                  )"
                                  :key="lender_lyr.id"
                                >
                                  <v-expansion-panel-header>
                                    <div class="d-flex align-center">
                                      {{
                                        lender_lyr.user.companies
                                          .map((company) => company.name)
                                          .join(", ")
                                      }}
                                      -
                                      {{ lender_lyr.user.first_name }}
                                      {{ lender_lyr.user.last_name }}
                                      {{ lender_lyr.active ? " (Active)" : "" }}
                                    </div>
                                  </v-expansion-panel-header>

                                  <v-divider />

                                  <v-expansion-panel-content>
                                    <v-simple-table class="mt-5" dense>
                                      <tbody>
                                        <tr>
                                          <td>Primary Email</td>

                                          <td>
                                            {{ lender_lyr.user.email }}
                                          </td>
                                        </tr>

                                        <tr
                                          v-for="contact_info in lender_lyr.user
                                            .contact_infos"
                                          :key="contact_info.id"
                                        >
                                          <td>{{ contact_info.type }}</td>

                                          <td>
                                            {{
                                              contact_info.type !== "Email"
                                                ? contact_info.number
                                                : contact_info.email
                                            }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>

                                    <!-- list of notes -->

                                    <v-data-table
                                      class="mt-5"
                                      :headers="lawyer_note_headers"
                                      :items="lender_lyr.notes"
                                      :items-per-page="5"
                                      dense
                                    />

                                    <div>
                                      <v-btn
                                        class="mt-5"
                                        @click="
                                          handle_archive_lawyer(
                                            lender_lyr.id,
                                            'lender'
                                          )
                                        "
                                      >
                                        unarchive
                                      </v-btn>
                                    </div>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <!-- lender lawyer action -->
                          <div class="mt-5">
                            <v-btn
                              v-if="!adding_lender_lawyer"
                              @click="adding_lender_lawyer = true"
                            >
                              Add Lawyer
                            </v-btn>
                          </div>

                          <!-- add lender lawyer -->
                          <div v-if="adding_lender_lawyer">
                            <v-text-field
                              v-model="search_lender_lawyers"
                              label="Search"
                              @input="
                                search_lender_lawyers =
                                  search_lender_lawyers.toLowerCase()
                              "
                              single-line
                              hide-details
                            />

                            <v-data-table
                              :headers="lawyer_headers"
                              :items="
                                company_lawyers.filter((el) => {
                                  return (
                                    !lender_lyrs
                                      .map((el) => el.user.id)
                                      .includes(el.id) &&
                                    !borrower_lyrs
                                      .map((el) => el.user.id)
                                      .includes(el.id)
                                  );
                                })
                              "
                              :items-per-page="5"
                              :search="search_lender_lawyers"
                              :custom-filter="customFilter"
                              @click:row="handle_add_lawyer($event)"
                            >
                              <template v-slot:item.companies="{ item }">
                                {{
                                  item.companies
                                    .map((company) => company.name)
                                    .join(", ")
                                }}
                              </template>
                            </v-data-table>

                            <v-btn
                              class="mt-5"
                              @click="adding_lender_lawyer = false"
                            >
                              cancel
                            </v-btn>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Deal Summary -->
                <v-expansion-panel ref="deal_summaries" class="bordered">
                  <v-expansion-panel-header>
                    <h3 v-if="dealData.deal_summaries != null">
                      Deal Summaries

                      <v-icon class="ml-4" style="cursor: pointer">
                        mdi-currency-usd
                      </v-icon>
                    </h3>

                    <h3 v-if="dealData.deal_summaries == null">
                      Deal Summary
                      <span>(please add deal summary)</span>

                      <v-icon
                        class="ml-4"
                        style="cursor: pointer"
                        @click.stop="addNote"
                      >
                        mdi-currency-usd
                      </v-icon>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div v-if="dealData.deal_summaries != null">
                      <v-expansion-panels multiple>
                        <v-expansion-panel
                          v-for="(
                            summary, summary_index
                          ) in dealData.deal_summaries"
                          :key="summary.id"
                          class="bordered"
                        >
                          <v-expansion-panel-header
                            :class="{
                              active_summary: summary.status == 'active',
                              inactive_summary: summary.status == 'archive',
                            }"
                          >
                            {{ summary.amount_requested | currency }} - LTV:
                            {{ summary.loan_to_value }}% - Created:
                            {{ summary.createdAt | dateFormat }} - Status:
                            {{ summary.status }}
                            <span style="margin-left: 30px">
                              <v-icon
                                class="ml-4"
                                style="cursor: pointer"
                                left
                                small
                                @click.prevent="
                                  $router.push({
                                    path: `/dashboard/admin/deal_summary/edit/${summary.id}`,
                                  })
                                "
                              >
                                mdi-pencil-outline
                              </v-icon>
                            </span>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-expansion-panels multiple>
                              <v-expansion-panel class="bordered">
                                <v-expansion-panel-header>
                                  Deal Summary Information
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-simple-table dense>
                                    <thead />

                                    <tbody>
                                      <tr>
                                        <td style="width: 250px">ID</td>

                                        <td>
                                          {{ summary.id }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">Status</td>

                                        <td>
                                          {{ summary.status | capitalize }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td />
                                        <td />
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">City</td>

                                        <td>
                                          {{
                                            summary.city
                                              | capitalize_first_letter
                                          }},
                                          {{ summary.province }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Location Notes
                                        </td>

                                        <td>{{ summary.location_notes }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Property Type
                                        </td>

                                        <td>{{ summary.property_type }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Property Value
                                        </td>

                                        <td>
                                          {{
                                            summary.property_value | currency
                                          }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Property Value Notes
                                        </td>

                                        <td>
                                          {{ summary.property_value_notes }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px"></td>

                                        <td />
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Owing Against Property
                                        </td>

                                        <td>
                                          {{ summary.owing_against | currency }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Owing Against Property Notes
                                        </td>

                                        <td>
                                          {{ summary.owing_against_notes }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px"></td>

                                        <td />
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Amount Requested
                                        </td>

                                        <td>
                                          {{
                                            summary.amount_requested | currency
                                          }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Amount Requested Notes
                                        </td>

                                        <td>
                                          {{ summary.amount_requested_notes }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Loan To Value
                                        </td>

                                        <td>{{ summary.loan_to_value }}%</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Mortgage Position
                                        </td>

                                        <td>
                                          {{ summary.mortgage_position }}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px"></td>

                                        <td />
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Income Notes
                                        </td>

                                        <td>{{ summary.income_notes }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Liability Notes
                                        </td>

                                        <td>{{ summary.liability_notes }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Credit Notes
                                        </td>

                                        <td>{{ summary.credit_notes }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Exit Strategy
                                        </td>

                                        <td>{{ summary.exit_strategy }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Summary Notes
                                        </td>

                                        <td>{{ summary.summary_notes }}</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">GDS</td>

                                        <td>{{ summary.GDS }}%</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">TDS</td>

                                        <td>{{ summary.TDS }}%</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px"></td>

                                        <td />
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Proposed Interest Rate
                                        </td>

                                        <td>{{ summary.proposed_rate }}%</td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Proposed Lender Fee
                                        </td>

                                        <td>
                                          {{ summary.proposed_lender_fee }}%
                                        </td>
                                      </tr>

                                      <tr>
                                        <td style="width: 250px">
                                          Proposed Term
                                        </td>

                                        <td>
                                          {{ summary.proposed_term }} Months
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel class="bordered">
                                <v-expansion-panel-header>
                                  Offers (
                                  {{ summary.summary_offers.length }} )
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                  <div
                                    style="max-width: 100%; overflow-x: auto"
                                  >
                                    <v-simple-table id="offer_table">
                                      <thead>
                                        <th :width="250">Created</th>

                                        <th>Company</th>

                                        <th>Account</th>

                                        <th>Amount</th>

                                        <th>Interest Rate</th>

                                        <th>Lender Fee</th>

                                        <th>Position</th>

                                        <th>Term</th>

                                        <th>Term Type</th>

                                        <th>Conditions</th>

                                        <th>Credit</th>

                                        <th>Income</th>

                                        <th>Status</th>

                                        <th>Made By</th>
                                      </thead>

                                      <tbody>
                                        <tr
                                          v-for="offer in summary.summary_offers"
                                          :key="offer.id"
                                          @click="
                                            open_summary_offer(
                                              summary.id,
                                              offer.id
                                            )
                                          "
                                        >
                                          <td>
                                            {{ offer.createdAt | dateFormat }}
                                          </td>

                                          <td>{{ offer.company.name }}</td>

                                          <td>
                                            {{
                                              offer.account
                                                ? offer.account.type === "Cash"
                                                  ? "Cash"
                                                  : offer.account.name
                                                : ""
                                            }}
                                          </td>

                                          <td>
                                            {{
                                              offer.mortgage_amount | currency
                                            }}
                                          </td>

                                          <td>{{ offer.interest_rate }}%</td>

                                          <td>{{ offer.lender_fee }}%</td>

                                          <td>{{ offer.mortgage_position }}</td>

                                          <td>
                                            {{ offer.mortgage_term }} Months
                                          </td>

                                          <td>
                                            {{
                                              offer.term_type
                                                ? offer.term_type ===
                                                  "Open - After Months"
                                                  ? ` Open - After ${
                                                      offer.number_of_months > 1
                                                        ? `${offer.number_of_months} Months`
                                                        : `${offer.number_of_months} Month`
                                                    }`
                                                  : `${offer.term_type}`
                                                : ""
                                            }}
                                          </td>

                                          <td>
                                            {{ offer.condition }}
                                          </td>

                                          <td>
                                            {{
                                              offer.credit_check | booleanString
                                            }}
                                          </td>

                                          <td>
                                            {{
                                              offer.income_verification
                                                | booleanString
                                            }}
                                          </td>

                                          <td>{{ offer.status }}</td>

                                          <td>
                                            {{ offer.user.last_name }} ,
                                            {{ offer.user.first_name }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel class="bordered">
                                <v-expansion-panel-header>
                                  Assigned Lenders
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                  <v-simple-table dense>
                                    <thead>
                                      <th>Assigned</th>

                                      <th>Lender</th>

                                      <th>User</th>

                                      <th>Viewed</th>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="assigned in summary.deal_summary_access_links"
                                        :key="assigned.id"
                                      >
                                        <td>
                                          {{ assigned.createdAt | dateFormat }}
                                        </td>

                                        <td>{{ assigned.company.name }}</td>

                                        <td>
                                          {{ assigned.user.first_name }}
                                          {{ assigned.user.last_name }}
                                        </td>

                                        <td>
                                          {{
                                            last_viewed(
                                              assigned.user.id,
                                              summary_index
                                            )
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>

                                  <br />

                                  <br />

                                  <v-btn
                                    v-if="summary.status == 'active'"
                                    link
                                    :to="{
                                      path: `/dashboard/admin/deal/assign_deal_summary/${summary.id}`,
                                    }"
                                  >
                                    Assign Lenders
                                  </v-btn>

                                  <v-btn
                                    class="ml-5"
                                    v-if="summary.status == 'active'"
                                    link
                                    :to="{
                                      path: `/dashboard/admin/deal/resend_to_lenders/${summary.id}`,
                                    }"
                                  >
                                    Resend to Lenders
                                  </v-btn>

                                  <p v-else>
                                    Deal summary must be active to assign
                                    lenders to deal summary
                                  </p>
                                </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel class="bordered">
                                <v-expansion-panel-header>
                                  Lender View Log
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                  <v-simple-table dense>
                                    <thead>
                                      <th>Viewed</th>

                                      <th>Lender</th>

                                      <th>User</th>

                                      <th>Type</th>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="view in summary.summary_view_logs"
                                        :key="view.id"
                                      >
                                        <td>
                                          {{
                                            new Date(
                                              view.createdAt
                                            ).toLocaleString()
                                          }}
                                        </td>

                                        <td>{{ view.company.name }}</td>

                                        <td>
                                          {{ view.user.first_name }}
                                          {{ view.user.last_name }}
                                        </td>

                                        <td>
                                          {{ view.type }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>

                                  <br />

                                  <br />

                                  <v-btn
                                    link
                                    :to="{
                                      path: `/dashboard/admin/deal/assign_deal_summary/${summary.id}`,
                                    }"
                                  >
                                    Assign Lenders
                                  </v-btn>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <br /><br />
                      <v-btn
                        dark
                        @click="
                          $router.push({
                            path: `/dashboard/admin/deal_summary/add/${dealData.id}`,
                          })
                        "
                      >
                        Create New Deal Summary
                      </v-btn>
                    </div>

                    <div v-if="dealData.deal_summaries == null">
                      <p>There is no deal summary available.</p>

                      <div class="mt-4">
                        <v-btn
                          dark
                          @click="
                            $router.push({
                              path: `/dashboard/admin/deal_summary/add/${dealData.id}`,
                            })
                          "
                        >
                          Create Deal Summary
                        </v-btn>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Deal Files -->
                <v-expansion-panel ref="file" class="bordered">
                  <v-expansion-panel-header>
                    <h3>Files</h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <v-btn @click="open_file_dialog = true">
                          <v-icon class="mr-2">
                            mdi-folder-open-outline
                          </v-icon>
                          Show Files
                        </v-btn>
                      </v-col>

                      <v-col>
                        <v-btn @click="upload_file_dialog = true">
                          <v-icon class="mr-2">
                            mdi-cloud-upload-outline
                          </v-icon>
                          Upload Files
                        </v-btn>
                      </v-col>

                      <v-col>
                        <v-btn
                          v-show="
                            $store.getters['Auth/getAuthUser'].email ===
                              'kim@gmail.com' ||
                            $store.getters['Auth/getAuthUser'].email ===
                              'patrick@equityapproved.ca' ||
                            $store.getters['Auth/getAuthUser'].email ===
                              'psierak@gmail.com'
                          "
                          @click="manage_file_category = true"
                        >
                          <v-icon class="mr-2"> mdi-shape-plus-outline </v-icon>
                          File Category
                        </v-btn>
                      </v-col>

                      <v-col>
                        <v-btn
                          v-show="
                            $store.getters['Auth/getAuthUser'].email ===
                              'kim@gmail.com' ||
                            $store.getters['Auth/getAuthUser'].email ===
                              'patrick@equityapproved.ca' ||
                            $store.getters['Auth/getAuthUser'].email ===
                              'psierak@gmail.com'
                          "
                          @click="manage_file_type = true"
                        >
                          <v-icon class="mr-2"> mdi-file-plus-outline </v-icon>
                          File Type
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row v-if="manage_file_category">
                      <v-col>
                        <ManageFileCategory
                          @close="manage_file_category = false"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="manage_file_type">
                      <v-col>
                        <ManageFileType @close="manage_file_type = false" />
                      </v-col>
                    </v-row>

                    <!-- show files dialog -->
                    <v-dialog
                      v-model="open_file_dialog"
                      transition="dialog-bottom-transition"
                      fullscreen
                    >
                      <ReadFile
                        v-if="open_file_dialog"
                        view_type="Deal"
                        :customer_last_name="
                          dealData.int_custs.find((el) => el.main_applicant)
                            ? `${
                                dealData.int_custs.find(
                                  (el) => el.main_applicant
                                ).customer.last_name
                              }`
                            : ''
                        "
                        :deal_public_name="dealData.public_name"
                        :deal_id="dealData.id"
                        @close-file-dialog="open_file_dialog = false"
                        @open-upload-file-dialog="
                          open_file_dialog = false;
                          upload_file_dialog = true;
                        "
                      />
                    </v-dialog>

                    <!-- upload files dialog -->
                    <v-dialog
                      v-model="upload_file_dialog"
                      transition="dialog-bottom-transition"
                      fullscreen
                      persistent
                    >
                      <UploadFile
                        v-if="upload_file_dialog"
                        view_type="Deal"
                        :deal_id="dealData.id"
                        @close-upload-file-dialog="upload_file_dialog = false"
                        @show-files="
                          upload_file_dialog = false;
                          open_file_dialog = true;
                        "
                      />
                    </v-dialog>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Funded Mortgage -->
                <v-expansion-panel ref="funded_mortgage" class="bordered">
                  <v-expansion-panel-header>
                    <h3>
                      Funded Mortgage
                      <v-btn
                        v-if="dealData.fund_morts.length !== 0"
                        icon
                        title="Edit Funded Mortgage"
                        @click.native.stop="
                          handle_update_funded_mortgage(
                            dealData.fund_morts[0].id
                          )
                        "
                      >
                        <v-icon> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <ReadFundMort
                      v-for="item in dealData.fund_morts"
                      :key="item.id"
                      :item="item"
                    />

                    <v-row>
                      <v-col>
                        <v-btn
                          v-if="dealData.fund_morts.length === 0"
                          @click="handle_create_funded_mortgage"
                        >
                          create funded mortgage
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Payment Activity -->
                <v-expansion-panel ref="payment_activity" class="bordered">
                  <v-expansion-panel-header>
                    <h3 class="d-flex align-center">
                      Payment Activity

                      {{ dealData.pymt ? " - " : "" }}

                      <span
                        v-if="dealData.pymt"
                        :class="
                          dealData.pymt.status === 'In Arrears'
                            ? 'ml-1 red--text'
                            : 'ml-1 white--text'
                        "
                      >
                        {{ dealData.pymt.status }}
                      </span>

                      <v-menu
                        v-if="dealData.pymt"
                        transition="slide-y-transition"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            title="Update Status"
                            class="ml-2"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon> mdi-pencil-outline </v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item link>
                            <v-list-item-title
                              @click="
                                dealData.pymt.status = 'Current';
                                show_save_payment = true;
                              "
                            >
                              Current
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item link>
                            <v-list-item-title
                              @click="
                                dealData.pymt.status = 'In Arrears';
                                show_save_payment = true;
                              "
                            >
                              In Arrears
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <v-btn
                        @click.native.stop="
                          pymt_log_dialog = true;
                          adding_payment = true;
                        "
                        class="ml-auto mr-5"
                        text
                      >
                        <v-icon> mdi-plus </v-icon> Create Entry
                      </v-btn>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-alert
                      v-show="show_save_payment"
                      transition="fade-transition"
                      color="orange darken-3"
                      dense
                      outlined
                    >
                      <div class="d-flex align-center">
                        <v-icon color="orange darken-3">
                          mdi-alert-outline
                        </v-icon>

                        <span class="ml-2">
                          Your changes have not been saved.
                        </span>
                      </div>
                    </v-alert>

                    <v-data-table
                      :headers="[
                        { text: 'Cleared', value: 'cleared' },
                        { text: 'Date', value: 'date' },
                        { text: 'Type', value: 'type' },
                        { text: 'Amount', value: 'amount' },
                        { text: 'Notes', value: 'notes' },
                        { text: '', value: '' },
                      ]"
                      :items="sortedPaymentLogs"
                      :items-per-page="-1"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            <v-checkbox
                              v-model="item.cleared"
                              color="green"
                              class="mr-7"
                              @change="show_save_payment = true"
                            />
                          </td>

                          <td>
                            {{ item.date | formatDate }}
                          </td>

                          <td>
                            {{ item.type }}
                          </td>

                          <td>
                            {{ String(item.amount) | formatPrice }}
                          </td>

                          <td>
                            <ul>
                              <li
                                v-for="note in item.notes"
                                :key="note.id"
                                class="mb-2"
                              >
                                <span>
                                  {{ note.note }} -
                                  <i class="grey--text">
                                    {{ note.createdAt | dateFormat }}
                                  </i>
                                </span>
                              </li>
                            </ul>
                          </td>

                          <td class="text-right">
                            <v-menu
                              transition="slide-y-transition"
                              left
                              offset-x
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  title="select action"
                                >
                                  <v-icon> mdi-dots-vertical </v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item
                                  link
                                  @click="handle_edit_payment(item.id)"
                                >
                                  <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  link
                                  @click="
                                    handle_delete_payment_activity(item.id)
                                  "
                                >
                                  <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  link
                                  @click="handle_copy_payment(item.id)"
                                >
                                  <v-list-item-title> Copy </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>

                    <v-btn
                      v-if="show_save_payment"
                      @click="handle_submit_save_payment_log"
                      class="mt-4"
                      color="primary"
                    >
                      save
                    </v-btn>

                    <v-dialog
                      v-model="pymt_log_dialog"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      scrollable
                    >
                      <v-card v-if="pymt_log_dialog">
                        <v-card-text class="mt-10">
                          <AddPaymentLog
                            :viewed_as="
                              adding_payment
                                ? 'add'
                                : editing_payment
                                ? 'edit'
                                : copy_payment
                                ? 'copy'
                                : 'invalid view'
                            "
                            :payment_log="new_payment_log"
                            @submit_add="handle_save_payment_log"
                            @submit_edit="handle_update_payment_log"
                            @close="
                              pymt_log_dialog = false;
                              adding_payment = false;
                              editing_payment = false;
                              copy_payment = false;
                            "
                          />
                        </v-card-text>

                        <div style="flex: 1 1 auto"></div>
                      </v-card>
                    </v-dialog>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Note -->
                <v-expansion-panel ref="deal_note" class="bordered">
                  <v-expansion-panel-header>
                    <h3 class="d-flex align-center">
                      Notes
                      <v-icon class="ml-2"> mdi-note-edit-outline </v-icon>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- note list -->
                    <v-card
                      style="background: transparent"
                      ref="note_list"
                      flat
                    >
                      <p class="mb-0 grey--text font-weight-medium">
                        Click on the text field to input your notes, you can
                        also add screenshots or image files by copy/paste or
                        drag and drop into the text field below.
                      </p>

                      <v-card-text>
                        <p
                          v-if="dealData.notes.length === 0"
                          class="text-center grey--text text-subtitle-1"
                        >
                          No notes available. Make a note below.
                        </p>

                        <div
                          v-for="(note, index) in dealData.notes"
                          :key="note.id"
                          class="my-2"
                        >
                          <v-card class="rounded-lg">
                            <v-card-text>
                              <p class="d-flex align-center mb-0">
                                <span>
                                  <v-icon class="text-body-1 mr-1">
                                    mdi-account-outline
                                  </v-icon>
                                  {{ note.user ? note.user.first_name : "" }}
                                  {{ note.user ? note.user.last_name : "" }}
                                </span>

                                <span class="ml-auto">
                                  <v-icon class="text-body-1 mr-1">
                                    mdi-calendar-outline
                                  </v-icon>
                                  {{ note.createdAt | formatDate }}
                                </span>

                                <span class="ml-5">
                                  <v-btn
                                    title="Click to edit note"
                                    @click="
                                      handle_edit_deal_note(note, index, $event)
                                    "
                                    icon
                                    small
                                  >
                                    <v-icon color="teal" class="text-body-1">
                                      mdi-pencil-outline
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    title="Click to delete"
                                    @click="handle_delete_deal_note(note.id)"
                                    icon
                                    small
                                  >
                                    <v-icon color="red"> mdi-close </v-icon>
                                  </v-btn>
                                </span>
                              </p>
                            </v-card-text>

                            <v-divider />

                            <v-card-text>
                              <div v-if="note.note" style="white-space: pre">
                                {{ note.note }}
                              </div>

                              <div
                                v-if="note.content"
                                class="note-contents"
                                v-html="note.content"
                              ></div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-card-text>
                    </v-card>

                    <!-- note editor -->
                    <v-card
                      style="background: transparent"
                      ref="note_editor"
                      flat
                    >
                      <v-card-text class="pa-1">
                        <div>
                          <RichTextEditor
                            ref="tipTapEditor"
                            :id="deal_id"
                            type="deal"
                            :user_id="$store.getters['Auth/getAuthUser'].id"
                            @handleSaveNote="handle_save_deal_note"
                          />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-dialog v-model="note_dialog" fullscreen>
                <v-toolbar class="sticky-toolbar orange darken-3" flat>
                  <v-toolbar-title>
                    <v-icon>mdi-image</v-icon> Image Preview
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="note_dialog = false" title="Close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card>
                  <v-card-text>
                    <v-img :src="selectedImage" aspect-ratio="1" />
                  </v-card-text>
                </v-card>
              </v-dialog>

              <AdminOfferDetailsDialog
                @close_show_offer_details_dialog="
                  show_offer_details_dialog = false
                "
                :dialog_offer_data="dialog_offer_data"
                :dialog_offer_summary_data="dialog_offer_summary_data"
                :show_offer_details_dialog="show_offer_details_dialog"
                @reload_page_data="reloadPageData"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import { DateTime } from "luxon";

export default {
  name: "DealRead",

  props: ["deal_id"],

  components: {
    AdminOfferDetailsDialog: () =>
      import("@/components/AdminOfferDetailsDialog.vue"),
    ReadFile: () => import("@/components/deal/ReadFile.vue"),
    UploadFile: () => import("@/components/deal/UploadFile.vue"),
    ReadFundMort: () =>
      import("@/components/funded_mortgage/Read_Fund_Mort.vue"),
    AddExistingCustomer: () =>
      import("@/components/customer/AddExistingCustomer.vue"),
    ObjectiveList: () => import("@/components/kanban/Objective_List.vue"),
    AddPaymentLog: () =>
      import("@/components/payment_activity/AddPaymentLog.vue"),
    RichTextEditor: () => import("@/components/note/TipTapEditor.vue"),
    AddLinkForm: () => import("@/components/deal/Add_Link_Form.vue"),
    ManageFileCategory: () =>
      import("@/components/deal/ManageFileCategory.vue"),
    ManageFileType: () => import("@/components/deal/ManageFileType.vue"),
  },

  data() {
    return {
      open_file_dialog: false,
      upload_file_dialog: false,
      manage_file_category: false,
      manage_file_type: false,

      error: false,
      errorMessage: "",
      status_submitted: false,
      warning: false,
      warningMessage: "",
      payment_saved: false,

      panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      show_offer_details_dialog: false,
      dialog_offer_data: null,
      dialog_offer_summary_data: null,
      dialog_filogix: false,
      loadingNotes: false,
      addNoteActive: false,
      formLoading: false,
      componentLoading: true,
      adding_existing_customer: false,
      loading_int_cust: false,
      pymt_log_dialog: false,
      adding_payment: false,
      editing_payment: false,
      copy_payment: false,
      show_save_payment: false,

      // for links
      dialog_link: false,
      is_edit_link: false,
      temp_link_index: null,

      new_payment_log: null,

      new_broker_fee_value: null,
      new_broker_fee_value_chance: null,
      new_estimated_value: null,
      new_deal_status: null,
      new_funded_type: null,
      new_closed_type: null,
      new_paid_out_type: null,

      search_borrower_lawyers: "",
      search_lender_lawyers: "",
      adding_borrower_lawyer: false,
      adding_lender_lawyer: false,
      new_borrower_lawyer_note: "",
      temp_borrower_lawyer_note_index: null,
      new_lender_lawyer_note: "",
      temp_lender_lawyer_note_index: null,

      // note section
      note_dialog: false,
      selectedImage: "",
      temp_deal_note_index: null,

      submitError: "",
      search_lawyers: "",

      lawyer_headers: [
        { text: "Name", value: "first_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Company", value: "companies" },
      ],

      lawyer_note_headers: [{ text: "Note", value: "note" }],

      customer_financial_info_note_header: [{ text: "Note", value: "note" }],

      status_options: [
        { value: "In Progress", text: "In Progress" },
        { value: "Funded", text: "Funded" },
        { value: "Closed", text: "Closed" },
        { value: "Not Completed / Archive", text: "Not Completed / Archive" },
      ],
      new_funded_type_options: [
        { value: "In-Term", text: "In-Term" },
        {
          value: "Matured (Not renewed or refi)",
          text: "Matured (Not renewed or refi)",
        },
        { value: "On-Going Legal", text: "On-Going Legal" },
      ],
      new_closed_type_options: [
        { value: "Paid Out", text: "Paid Out" },
        { value: "Renewed", text: "Renewed" },
      ],
      new_paid_out_type_options: [
        { value: "Refinanced", text: "Refinanced" },
        { value: "Sold Property", text: "Sold Property" },
        { value: "Foreclosed", text: "Foreclosed" },
      ],

      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
        number: [(value) => !isNaN(value) || "This field must be a number."],
        max100: [
          (value) => value <= 100 || "The number must not be greater than 100.",
        ],
      },

      dealData: {},
      dealContacts: {},
      dealAddresses: {},
      dealAccounts: {},
      assignedLenders: {},

      borrower_lyrs: [],
      lender_lyrs: [],

      unassociated_customers: [],
      customer_suggestions: [],

      company_lawyers: [],
    };
  },

  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    },

    scrollToElement(element) {
      window.scrollTo({
        top: this.getOffsetTop(element) - 100, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    handle_save_deal_note(note, it_is_edit) {
      if (it_is_edit) {
        this.dealData.notes[this.temp_deal_note_index].content = note.content;
        this.temp_deal_note_index = null;

        const note_list = this.$refs.note_list.$el;
        this.scrollToElement(note_list);
      } else {
        this.dealData.notes.push(note);
      }
    },

    async handle_delete_deal_note(id) {
      try {
        if (window.confirm("Are you sure you want to delete this note?")) {
          this.loadingNotes = true;
          const response = await API().post(
            `/api/internal-admin/note/delete?note_id=${id}`
          );

          console.log("Response: ", response.message);

          this.dealData.notes = this.dealData.notes.filter(
            (note) => note.id !== id
          );
        } else {
          console.log("Delete cancelled");
          return;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    handle_edit_deal_note(note, index, event) {
      event.preventDefault();

      this.temp_deal_note_index = index;

      // set the editor content
      this.$refs.tipTapEditor.setContentToEdit(note);

      const note_editor = this.$refs.note_editor.$el;
      this.scrollToElement(note_editor);
    },

    onDealNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },

    handle_create_property() {
      this.$router.push({
        path: `/dashboard/admin/property/${this.dealData.id}?type=create`,
      });
    },

    last_viewed(user_id, summary_index) {
      let user_view_logs = this.dealData.deal_summaries[
        parseInt(summary_index)
      ].summary_view_logs.filter((el) => {
        if (el.user.id == user_id) {
          return el;
        }
      });

      if (user_view_logs.length) {
        return "Viewed";
      } else {
        return "Not Viewed";
      }
    },

    open_summary_offer(summary_id, offer_id) {
      let summary = this.dealData.deal_summaries.filter((summary) => {
        return summary.id == summary_id;
      });

      let offer = summary[0].summary_offers.filter((offer) => {
        return offer.id == offer_id;
      });

      this.dialog_offer_data = offer[0];
      this.dialog_offer_summary_data = summary[0];
      this.show_offer_details_dialog = true;
    },

    // close_show_offer_details_dialog(){
    //   alert('close_show_offer_details_dialog')
    //   this.show_offer_details_dialog = false;
    // },

    async archive_deal() {
      try {
        let archived_deal = await API().patch(
          `/api/internal-admin/archive_deal/?deal_id=${this.deal_id}`
        );
        this.loadPageData();
      } catch (error) {
        alert(error);
      }
    },

    async change_deal_to_draft() {
      try {
        let archived_deal = await API().patch(
          `/api/internal-admin/change_deal_to_draft/?deal_id=${this.deal_id}`
        );
        this.loadPageData();
      } catch (error) {
        alert(error);
      }
    },

    async unaward(lenderId) {
      let unawardConfirm = confirm(
        "Are you sure you want to unaward the deal?"
      );

      if (unawardConfirm) {
        let dealUnaward = await API().post("/dealUnaward", {
          dealId: this.dealId,
          lenderId: lenderId,
        });

        if (dealUnaward.status == 200) {
          this.loadPageData();
        } else {
          alert("the deal was not awarded, there was an error");
        }
      } else {
        alert("you did not award it");
      }
    },

    async awardDeal(lenderId) {
      let awarded = confirm("Are you sure you want to award the deal?");
      if (awarded) {
        let dealAward = await API().post("/dealAward", {
          dealId: this.dealId,
          lenderId: lenderId,
        });

        if (dealAward.status == 200) {
          this.loadPageData();
        } else {
          alert("the deal was not awarded, there was an error");
        }
      } else {
        alert("you did not award it");
      }
    },

    async toggleLenderActive(index) {
      this.$set(this.assignedLenders[index], "loading", true);
      // this.assignedLenders[index].loading = true;

      let modifyLenderActive = await API().patch("/lenderDealPivotActive", {
        id: this.assignedLenders[index].id,
        active: this.assignedLenders[index].active,
      });

      if (modifyLenderActive.status == 202) {
        console.log("the status is 202");
        this.assignedLenders[index].loading = false;
      } else {
        alert(
          "there was an error updating the status!!! status was not updated"
        );
      }
    },

    addNote() {
      this.addNoteActive = true;
    },

    closeNote() {
      this.addNoteActive = false;
    },

    submitForm() {
      this.loading = true;
      this.submitError = "";
      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/deal/add", this.dealData)
          .then((res) => {
            this.loading = false;

            alert(JSON.stringify(res.data));
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },

    loadPageData() {
      this.componentLoading = true;

      this.read_customer_suggestions();
      this.read_all_customer();
      this.read_all_company_lawyer();

      API()
        .get(`api/internal-admin/dealRead/${this.$route.params.deal_id}`)
        .then((res) => {
          this.dealData = res.data;

          const borrower_lyrs = this.dealData.int_lyrs.filter(
            (el) => el.type == "borrower"
          );

          for (const borrower_lyr of borrower_lyrs) {
            this.borrower_lyrs.push({
              active: borrower_lyr.active,
              adding_note: false,
              archived: borrower_lyr.archived,
              id: borrower_lyr.id,
              notes: borrower_lyr.notes,
              selected_notes: [],
              type: "borrower",
              updating_note: false,
              user: borrower_lyr.user,
            });
          }

          const lender_lyrs = this.dealData.int_lyrs.filter(
            (el) => el.type == "lender"
          );

          for (const lender_lyr of lender_lyrs) {
            this.lender_lyrs.push({
              active: lender_lyr.active,
              adding_note: false,
              archived: lender_lyr.archived,
              id: lender_lyr.id,
              notes: lender_lyr.notes,
              selected_notes: [],
              type: "lender",
              updating_note: false,
              user: lender_lyr.user,
            });
          }

          console.log("deal: ", res);

          this.handle_initializing_deal_status();
          this.handle_initializing_objectives();

          this.new_broker_fee_value = this.$options.filters.formatPrice(
            String(this.dealData.broker_fee_value)
          );

          this.new_broker_fee_value_chance =
            this.dealData.broker_fee_value_chance;

          this.new_estimated_value = this.$options.filters.formatPrice(
            String(this.dealData.estimated_value)
          );

          const filtered_int_custs = this.dealData.int_custs.filter(
            (el) => el.customer
          );

          if (filtered_int_custs.length === 1) {
            this.toggle_int_cust_main_applicant(
              this.dealData.int_custs[0].id,
              true
            );
          }

          // this is for deal summary offers conditions
          for (const deal_summary of this.dealData.deal_summaries) {
            for (const summary_offer of deal_summary.summary_offers) {
              let condition_string = "";

              summary_offer.appraisal
                ? (condition_string = condition_string.concat("Appraisal "))
                : "";

              summary_offer.insurance
                ? (condition_string = condition_string.concat(
                    " - Insurance with lender "
                  ))
                : "";

              summary_offer.credit_check
                ? (condition_string =
                    condition_string.concat(" - Credit check "))
                : "";

              summary_offer.income_verification
                ? (condition_string = condition_string.concat(
                    " - Income documents "
                  ))
                : "";

              summary_offer.tax_verification
                ? (condition_string = condition_string.concat(
                    " - Personal income tax "
                  ))
                : "";

              summary_offer.strata
                ? (condition_string = condition_string.concat(
                    " - Strata/estoppel/condo documents "
                  ))
                : "";

              summary_offer.addl_conditions.forEach((item) => {
                condition_string = condition_string.concat(
                  ` - ${item.condition} `
                );
              });

              summary_offer.condition = condition_string;
            }
          }

          this.componentLoading = false;
          this.$nextTick(() => {
            if (this.$refs.note_list) {
              this.$refs.note_list.$el.addEventListener(
                "click",
                this.onDealNoteImageClick
              );
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    reloadPageData() {
      API()
        .get(`api/internal-admin/dealRead/${this.$route.params.deal_id}`)
        .then((res) => {
          this.dealData = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // this is to refresh deal files list after uploading a file
    refresh_deal_files() {
      this.$refs.dealFiles.getDealFile();
    },

    handle_create_funded_mortgage() {
      this.$router.push({
        path: `/dashboard/admin/deal/funded_mortgage/${this.dealData.id}/create`,
      });
    },

    handle_update_funded_mortgage(id) {
      this.$router.push({
        path: `/dashboard/admin/deal/funded_mortgage/${this.dealData.id}/edit?fund_mort_id=${id}`,
      });
    },

    async toggle_int_cust_main_applicant(id, value) {
      try {
        const response = await API().patch(
          `/api/internal-admin/customer/toggle_int_cust_main_applicant`,
          {
            id: id,
            value: value,
            deal_id: this.dealData.id,
          }
        );

        // console.log("toggle_main_applicant res: ", response);

        this.dealData.int_custs = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async toggle_int_prop_subject(id, value) {
      try {
        const response = await API().patch(
          `/api/internal-admin/customer/toggle_int_prop_subject`,
          {
            id: id,
            value: value,
          }
        );

        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async read_customer_suggestions() {
      try {
        const response = await API().get(
          `api/internal-admin/customer/read_deal_customer_suggestions?deal_id=${this.$route.params.deal_id}`
        );

        this.customer_suggestions = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async read_all_customer() {
      try {
        const response = await API().get(
          `api/internal-admin/customer/read_all_customers`
        );

        this.unassociated_customers = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async read_int_props() {
      try {
        const response = await API().get(
          `api/internal-admin/property/read_int_props?id=${this.dealData.id}&type=deal`
        );

        this.dealData.int_props = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async read_all_company_lawyer() {
      try {
        const response = await API().get(
          `api/internal-admin/lawyer/read_all_lawyers`
        );

        this.company_lawyers = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async associate_customer(customer_id) {
      try {
        this.loading_int_cust = true;
        let res = await API().post(
          "api/internal-admin/customer/associate_customer_to_deal",
          {
            deal_id: this.dealData.id,
            customer_id: customer_id,
          }
        );

        if (res.status === 201) {
          this.dealData.int_custs.push(res.data);

          this.read_customer_suggestions();
          if (this.dealData.status === "In Progress") this.read_int_props();

          const filtered_int_custs = this.dealData.int_custs.filter(
            (el) => el.customer
          );

          const customer_index = filtered_int_custs.findIndex(
            (el) => Number(el.customer.id) === Number(customer_id)
          );

          console.log("customer_index: ", customer_index);

          if (customer_index === 0) {
            this.toggle_int_cust_main_applicant(
              filtered_int_custs[customer_index].id,
              true
            );
          }

          this.adding_existing_customer = false;
          this.loading_int_cust = false;
        } else {
          this.error = true;
          this.errorMessage =
            "Something went wrong in associating customer to the deal.";

          setTimeout(() => {
            this.error = false;
            this.errorMessage = "";
            this.loading_int_cust = false;
          }, 5000);
        }
      } catch (error) {
        alert(error);
        this.loading_int_cust = true;
      }
    },

    async handle_unassociate_customer(id) {
      if (
        window.confirm(`Are you sure you want to unassociate this customer?`)
      ) {
        try {
          const response = await API().post(
            `api/internal-admin/customer/unassociate_customer_to_deal`,
            {
              int_cust_id: id,
            }
          );

          if (response.status === 201) {
            const index = this.dealData.int_custs.findIndex(
              (int_cust) => int_cust.id === id
            );

            this.dealData.int_custs.splice(index, 1);
            if (this.dealData.status === "In Progress") this.read_int_props();
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong in unassociating a deal.";

            setTimeout(() => {
              this.error = false;
              this.errorMessage = "";
            }, 5000);
          }
        } catch (error) {
          this.error = true;
          this.errorMessage = error.response.data.message;
        }
      } else {
        console.log(`Unassociating customer is cancelled.`);
      }
    },

    sync_customers() {
      if (
        window.confirm(
          "WARNING: this will sync all customers latest data to this deal. This will unselect Main Applicant and Subject Properties. Are you sure you want to continue?"
        )
      ) {
        const int_custs = this.dealData.int_custs;

        int_custs.forEach(async (int_cust) => {
          // unassociate customer
          try {
            const response = await API().post(
              `api/internal-admin/customer/unassociate_customer_to_deal`,
              {
                int_cust_id: int_cust.id,
              }
            );

            if (response.status === 201) {
              const index = this.dealData.int_custs.findIndex(
                (el) => el.id === int_cust.id
              );

              this.dealData.int_custs.splice(index, 1);
              if (this.dealData.status === "In Progress") this.read_int_props();
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in unassociating a deal.";

              setTimeout(() => {
                this.error = false;
                this.errorMessage = "";
              }, 5000);
            }
          } catch (error) {
            console.log(error);
          }
        });

        int_custs.forEach((int_cust) => {
          this.associate_customer(int_cust.customer.id);
        });
      } else {
        console.log(`Syncing customers is cancelled.`);
      }
    },

    async handle_add_lawyer(user_data) {
      if (this.adding_borrower_lawyer || this.adding_lender_lawyer) {
        try {
          const response = await API().post(
            `api/internal-admin/lawyer/add_lawyer`,
            {
              deal_id: this.dealData.id,
              lawyer_id: user_data.id,
              type: this.adding_borrower_lawyer ? "borrower" : "lender",
            }
          );

          console.log(response);

          if (response.status === 201) {
            console.log("Lawyer Added: ", response.data);

            if (this.adding_borrower_lawyer) {
              this.borrower_lyrs.push({
                active: response.data.active,
                archived: response.data.archived,
                user: response.data.user,
                type: response.data.type,
                notes: response.data.notes,
                adding_note: false,
                updating_note: false,
                selected_notes: [],
                id: response.data.id,
              });
            } else {
              this.lender_lyrs.push({
                active: response.data.active,
                archived: response.data.archived,
                user: response.data.user,
                type: response.data.type,
                notes: response.data.notes,
                adding_note: false,
                updating_note: false,
                selected_notes: [],
                id: response.data.id,
              });
            }

            this.adding_borrower_lawyer = false;
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong in adding a lawyer.";
            this.adding_borrower_lawyer = false;
          }
        } catch (error) {
          console.log("Error Message:", error.response.data.message);
          this.error = true;
          this.errorMessage = "Error Message: " + error.response.data.message;
          this.adding_borrower_lawyer = false;
        }
      } else {
        return;
      }
    },

    async handle_remove_lawyer(id, type) {
      try {
        if (window.confirm("Are you sure you want to remove this lawyer?")) {
          if (type === "borrower") {
            const response = await API().post(
              `api/internal-admin/lawyer/remove_lawyer`,
              {
                int_lyr_id: id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.borrower_lyrs.findIndex(
                (int_lyr) => int_lyr.id === id
              );

              this.borrower_lyrs.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in removing a lawyer from the deal.";
            }
          } else {
            const response = await API().post(
              `api/internal-admin/lawyer/remove_lawyer`,
              {
                int_lyr_id: id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.lender_lyrs.findIndex(
                (int_lyr) => int_lyr.id === id
              );

              this.lender_lyrs.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in removing a lawyer from the deal.";
            }
          }
        } else {
          console.log("removing lawyer canceled");
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.adding_borrower_lawyer = false;
      }
    },

    async handle_archive_lawyer(id, type) {
      try {
        let int_lyr = null;

        if (type === "borrower") {
          int_lyr = this.borrower_lyrs.find((int_lyr) => int_lyr.id === id);
        } else {
          int_lyr = this.lender_lyrs.find((int_lyr) => int_lyr.id === id);
        }

        const response = await API().patch(
          `api/internal-admin/lawyer/archive_lawyer`,
          {
            id: id,
            type: !int_lyr.archived,
          }
        );

        console.log(response);

        if (response.status === 201) {
          if (type === "borrower") {
            const index = this.borrower_lyrs.findIndex(
              (int_lyr) => int_lyr.id === id
            );

            this.borrower_lyrs[index].archived = !int_lyr.archived;
            this.borrower_lyrs[index].active = !int_lyr.active;
          } else {
            const index = this.lender_lyrs.findIndex(
              (int_lyr) => int_lyr.id === id
            );

            this.lender_lyrs[index].archived = !int_lyr.archived;
            this.lender_lyrs[index].active = !int_lyr.active;
          }
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in archiving a lawyer.";
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.adding_borrower_lawyer = false;
      }
    },

    async handle_save_lyr_note(id, type) {
      try {
        const response = await API().post(
          `api/internal-admin/lawyer/add_lawyer_note`,
          {
            int_lyr_id: id,
            note:
              type === "borrower"
                ? this.new_borrower_lawyer_note
                : this.new_lender_lawyer_note,
          }
        );

        console.log(response);

        if (response.status === 201) {
          if (type === "borrower") {
            const index = this.borrower_lyrs.findIndex(
              (int_lyr) => int_lyr.id === id
            );

            this.borrower_lyrs[index].notes.push(response.data);
            this.borrower_lyrs[index].adding_note = false;
            this.new_borrower_lawyer_note = "";
          } else {
            const index = this.lender_lyrs.findIndex(
              (int_lyr) => int_lyr.id === id
            );

            this.lender_lyrs[index].notes.push(response.data);
            this.lender_lyrs[index].adding_note = false;
            this.new_lender_lawyer_note = "";
          }
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in adding a lawyer note.";
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
      }
    },

    handle_update_lawyer_note(data, lyr_index, type) {
      if (type === "borrower") {
        this.temp_borrower_lawyer_note_index = this.borrower_lyrs[
          lyr_index
        ].notes.findIndex((note) => note.id === data.id);

        if (this.borrower_lyrs[lyr_index].adding_note) {
          alert("Please save the note first before updating");
          this.temp_borrower_lawyer_note_index = null;
          return;
        }

        this.new_borrower_lawyer_note = data.note;

        this.borrower_lyrs[lyr_index].updating_note = true;
      } else {
        this.temp_lender_lawyer_note_index = this.lender_lyrs[
          lyr_index
        ].notes.findIndex((note) => note.id === data.id);

        if (this.lender_lyrs[lyr_index].adding_note) {
          alert("Please save the note first before updating");
          this.temp_borrower_lawyer_note_index = null;
          return;
        }

        this.new_lender_lawyer_note = data.note;

        this.lender_lyrs[lyr_index].updating_note = true;
      }
    },

    async handle_save_updated_lyr_note(lyr_id, type) {
      try {
        let index = null;

        if (type === "borrower") {
          index = this.borrower_lyrs.findIndex(
            (borrower_lyr) => borrower_lyr.id === lyr_id
          );
        } else {
          index = this.lender_lyrs.findIndex(
            (lender_lyr) => lender_lyr.id === lyr_id
          );
        }

        const response = await API().patch(
          `api/internal-admin/lawyer/update_lawyer_note`,
          {
            id:
              type === "borrower"
                ? this.borrower_lyrs[index].notes[
                    this.temp_borrower_lawyer_note_index
                  ].id
                : this.lender_lyrs[index].notes[
                    this.temp_lender_lawyer_note_index
                  ].id,
            note:
              type === "borrower"
                ? this.new_borrower_lawyer_note
                : this.new_lender_lawyer_note,
          }
        );

        console.log(response);

        if (response.status === 201) {
          if (type === "borrower") {
            this.borrower_lyrs[index].notes[
              this.temp_borrower_lawyer_note_index
            ].note = response.data.note;

            this.borrower_lyrs[index].updating_note = false;

            this.new_borrower_lawyer_note = "";
            this.temp_borrower_lawyer_note_index = null;
          } else {
            this.lender_lyrs[index].notes[
              this.temp_lender_lawyer_note_index
            ].note = response.data.note;

            this.lender_lyrs[index].updating_note = false;

            this.new_lender_lawyer_note = "";
            this.temp_lender_lawyer_note_index = null;
          }
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in updating a lawyer note.";
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
      }
    },

    handle_delete_lawyer_note(lyr_id, type) {
      try {
        let index = null;

        if (type === "borrower") {
          index = this.borrower_lyrs.findIndex(
            (borrower_lyr) => borrower_lyr.id === lyr_id
          );
        } else {
          index = this.lender_lyrs.findIndex(
            (lender_lyr) => lender_lyr.id === lyr_id
          );
        }

        if (window.confirm("Are you sure you want to delete this?")) {
          if (type === "borrower") {
            this.borrower_lyrs[index].selected_notes.forEach(async (item) => {
              const response = await API().post(
                `api/internal-admin/lawyer/delete_lawyer_note`,
                {
                  id: item.id,
                }
              );

              console.log(response);

              if (response.status === 201) {
                this.borrower_lyrs[index].notes.splice(
                  this.borrower_lyrs[index].notes.indexOf(item),
                  1
                );
              } else {
                this.error = true;
                this.errorMessage =
                  "Something went wrong in deleting a lawyer note.";
              }
            });

            this.borrower_lyrs[index].selected_notes = [];
          } else {
            this.lender_lyrs[index].selected_notes.forEach(async (item) => {
              const response = await API().post(
                `api/internal-admin/lawyer/delete_lawyer_note`,
                {
                  id: item.id,
                }
              );

              console.log(response);

              if (response.status === 201) {
                this.lender_lyrs[index].notes.splice(
                  this.lender_lyrs[index].notes.indexOf(item),
                  1
                );
              } else {
                this.error = true;
                this.errorMessage =
                  "Something went wrong in deleting a lawyer note.";
              }
            });

            this.lender_lyrs[index].selected_notes = [];
          }
        } else {
          console.log("deleting note canceled");

          this.lender_lyrs[index].selected_notes = [];
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
      }
    },

    formatCompanies(item) {
      return item.companies.map((company) => company.name).join(", ");
    },

    customFilter(value, search, item) {
      return (
        value.toString().toLowerCase().indexOf(search) !== -1 ||
        this.formatCompanies(item).toLowerCase().indexOf(search) !== -1
      );
    },

    async handle_change_payment_status(status) {
      try {
        const response = await API().patch(
          `api/internal-admin/payment-activity/change_payment_status`,
          {
            pymt_id: this.dealData.pymt.id,
            status: status,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.dealData.pymt.status = status;
        } else {
          this.error = true;
          this.errorMessage =
            "Something went wrong in changing payment status.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = false;
      }
    },

    handle_initializing_deal_status() {
      switch (this.dealData.status) {
        case "Funded - In-Term":
          this.new_funded_type = "In-Term";
          this.new_deal_status = "Funded";
          break;

        case "Funded - Matured (Not renewed or refi)":
          this.new_funded_type = "Matured (Not renewed or refi)";
          this.new_deal_status = "Funded";
          break;

        case "Funded - On-Going Legal":
          this.new_funded_type = "On-Going Legal";
          this.new_deal_status = "Funded";
          break;

        case "Closed - Paid Out - Refinanced":
          this.new_closed_type = "Paid Out";
          this.new_paid_out_type = "Refinanced";
          this.new_deal_status = "Closed";
          break;

        case "Closed - Paid Out - Sold Property":
          this.new_closed_type = "Paid Out";
          this.new_paid_out_type = "Sold Property";
          this.new_deal_status = "Closed";
          break;

        case "Closed - Paid Out - Foreclosed":
          this.new_closed_type = "Paid Out";
          this.new_paid_out_type = "Foreclosed";
          this.new_deal_status = "Closed";
          break;

        case "Closed - Renewed":
          this.new_closed_type = "Renewed";
          this.new_deal_status = "Closed";
          break;

        case "In Progress":
          this.new_deal_status = "In Progress";
          this.new_funded_type = null;
          this.new_closed_type = null;
          this.new_paid_out_type = null;
          break;

        case "Not Completed / Archive":
          this.new_deal_status = "Not Completed / Archive";
          this.new_funded_type = null;
          this.new_closed_type = null;
          this.new_paid_out_type = null;
          break;

        default:
          this.new_deal_status = "In Progress";
          this.new_funded_type = null;
          this.new_closed_type = null;
          this.new_paid_out_type = null;
          break;
      }
    },

    async handle_quick_update_btn() {
      try {
        if (!this.$refs.quickUpdate.validate()) {
          this.status_submitted = false;
          this.error = true;
          this.errorMessage = "Please review the required fields.";
        } else {
          const response = await API().patch(
            `api/internal-admin/deal/deal_quick_update`,
            {
              deal_id: this.dealData.id,
              status: this.deal_status,
              broker_fee_value: this.new_broker_fee_value,
              broker_fee_value_chance: this.new_broker_fee_value_chance,
              estimated_value: this.new_estimated_value,
            }
          );

          console.log(response);

          if (response.status === 201) {
            this.dealData.status = this.deal_status;
            this.dealData.broker_fee_value = parseFloat(
              this.new_broker_fee_value.replace(/[$,]/g, "")
            );
            this.dealData.broker_fee_value_chance =
              this.new_broker_fee_value_chance;
            this.handle_initializing_deal_status();

            this.error = false;
            this.errorMessage = "";
            this.status_submitted = true;
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong in changing deal status.";
          }
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_edit_payment(id) {
      let payment_log = this.dealData.pymt.payment_logs.find(
        (log) => log.id === id
      );

      this.new_payment_log = JSON.parse(JSON.stringify(payment_log));

      console.log(this.new_payment_log);

      this.editing_payment = true;
      this.pymt_log_dialog = true;
    },

    handle_copy_payment(id) {
      let payment_log = this.dealData.pymt.payment_logs.find(
        (log) => log.id === id
      );

      this.new_payment_log = JSON.parse(JSON.stringify(payment_log));

      console.log(this.new_payment_log);

      this.copy_payment = true;
      this.pymt_log_dialog = true;
    },

    handle_delete_payment_activity(id) {
      if (window.confirm("Are you sure you want to delete this?")) {
        const index = this.dealData.pymt.payment_logs.findIndex(
          (log) => log.id === id
        );

        this.dealData.pymt.payment_logs =
          this.dealData.pymt.payment_logs.filter((log) => log.id !== id);

        this.show_save_payment = true;
      } else {
        console.log("deleting payment activity canceled");
      }
    },

    async handle_submit_save_payment_log() {
      try {
        const response = await API().post(
          `api/internal-admin/payment-activity/save`,
          {
            deal_id: this.dealData.id,
            payment_data: this.dealData.pymt,
          }
        );

        if (response.status === 201) {
          console.log(response);
          this.payment_saved = true;

          this.show_save_payment = false;
          this.pymt_log_dialog = false;
          this.adding_payment = false;
          this.editing_payment = false;
          this.copy_payment = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in saving payment log.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_save_payment_log(pymt_log, number_of_entries) {
      // Convert the selected date to a JavaScript Date object
      const startDate = DateTime.fromISO(pymt_log.date, { zone: "utc" });

      // Ensure that the date is interpreted as UTC, regardless of the user's time zone
      const startDateUTC = startDate.setZone("utc", { keepLocalTime: true });

      // Increment new_id outside the loop
      let new_id = 0;

      // Create payment log for each number_of_entries
      for (let i = 0; i < number_of_entries; i++) {
        let new_date = startDateUTC.plus({ months: i }).toISODate();

        let objCopy = Object.assign({}, pymt_log); // Creating a shallow copy of pymt_log

        // create pymt object if not exist
        if (!this.dealData.pymt) {
          this.dealData.pymt = {
            status: "Current",
            payment_logs: [],
          };
        }

        if (this.dealData.pymt) {
          if (!this.dealData.pymt.payment_logs) {
            this.dealData.pymt.payment_logs = [];
          }

          if (this.dealData.pymt.payment_logs.length > 0) {
            // get the highest order then add 1
            const highest_id = Math.max(
              ...this.dealData.pymt.payment_logs.map((item) => item.id)
            );
            new_id = highest_id + 1;
          }

          objCopy.id = new_id;
          objCopy.date = new_date;

          // Parse amount if it's a string
          if (typeof objCopy.amount === "string") {
            objCopy.amount = parseFloat(objCopy.amount.replace(/[$,]/g, ""));
          }

          // push new added payment log to the payment logs
          this.dealData.pymt.payment_logs.push(objCopy);
        }
      }

      console.log(this.dealData.pymt);
      this.show_save_payment = true;
      this.pymt_log_dialog = false;
      this.adding_payment = false;
      this.editing_payment = false;
      this.copy_payment = false;
    },

    handle_update_payment_log(pymt_data) {
      let index = this.dealData.pymt.payment_logs.findIndex(
        (log) => log.id === pymt_data.id
      );

      this.dealData.pymt.payment_logs[index].type = pymt_data.type;
      this.dealData.pymt.payment_logs[index].amount = pymt_data.amount;
      this.dealData.pymt.payment_logs[index].date = pymt_data.date;
      this.dealData.pymt.payment_logs[index].notes = [...pymt_data.notes];

      console.log(this.dealData.pymt.payment_logs[index]);
      this.new_payment_log = null;
      this.show_save_payment = true;
      this.pymt_log_dialog = false;
      this.adding_payment = false;
      this.editing_payment = false;
      this.copy_payment = false;
    },

    handle_estimated_value() {
      const result = parseFloat(
        this.new_broker_fee_value.replace(/[$,]/g, "") *
          (parseFloat(this.new_broker_fee_value_chance) / 100)
      );

      this.new_estimated_value = this.$options.filters.formatPrice(
        String(result)
      );
    },

    async handle_initializing_objectives() {
      try {
        if (this.dealData.kbn_stg) {
          if (this.dealData.stg_itms.length > 0) return;

          // update kanban stage item
          const kanban_res = await API().post(
            "api/kanban/update_kanban_stage_item",
            {
              stage_id: this.dealData.kbn_stg.id,
              item_id: this.dealData.id,
              type: "Deal",
              choice: "Archive and Create New",
            }
          );

          console.log("Stage Updated: ", kanban_res);
          this.reloadPageData();
        }

        if (!this.dealData.kbn_stg) {
          // get all kanban stages
          const kanban_stages = await API().get(
            "api/kanban/get_kanban_deal_data"
          );

          // if no stages found, show warning
          if (kanban_stages.length === 0) {
            this.error = false;
            this.status_submitted = false;
            this.warning = true;
            this.warningMessage =
              "No stages were found, cannot add objective to this deal. Please create stages in Kanban first.";
            return;
          }

          // create new stage item to the first kanban stage
          const kanban_res = await API().post(
            "api/kanban/update_kanban_stage_item",
            {
              stage_id: kanban_stages.data[0].id,
              item_id: this.dealData.id,
              type: "Deal",
              choice: "Archive and Create New",
            }
          );

          console.log("Stage Updated: ", kanban_res);

          this.reloadPageData();
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    async submit_link(data) {
      try {
        let response = null;

        if (this.is_edit_link) {
          response = await API().patch(`api/internal-admin/deal/edit-link`, {
            data: data,
          });
        } else {
          response = await API().post(`api/internal-admin/deal/add-link`, {
            deal_id: this.dealData.id,
            data: data,
          });
        }

        console.log(response);

        if (response.status === 201) {
          if (this.is_edit_link) {
            const index = this.dealData.links.findIndex(
              (link) => link.id === response.data.link.id
            );

            this.dealData.links[index] = { ...response.data.link };
          } else {
            this.dealData.links.push(response.data.link);
          }

          this.dialog_link = false;
          this.is_edit_link = false;
        } else {
          this.warning = true;
          this.warningMessage = "Something went wrong in submiting the link.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    edit_link(data) {
      this.is_edit_link = true;
      this.dialog_link = true;
      this.$nextTick(() => {
        this.$refs.link_form.setForm(data);
      });
    },

    async delete_link(id) {
      try {
        if (window.confirm("Are you sure you want to delete this?")) {
          const response = await API().post(
            `api/internal-admin/deal/delete-link`,
            {
              id: id,
            }
          );

          console.log(response);

          if (response.status === 200) {
            const index = this.dealData.links.findIndex(
              (link) => link.id === id
            );

            this.dealData.links.splice(index, 1);
          } else {
            this.warning = true;
            this.warningMessage = "Something went wrong in deleting a link.";
          }
        } else {
          console.log("deleting link canceled");
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },
  },

  computed: {
    deal_status() {
      if (this.new_deal_status == "Funded") {
        return `${this.new_deal_status} - ${this.new_funded_type}`;
      } else if (this.new_deal_status == "Closed") {
        switch (this.new_closed_type) {
          case "Paid Out":
            return `${this.new_deal_status} - ${this.new_closed_type} - ${this.new_paid_out_type}`;
          case "Renewed":
            return `${this.new_deal_status} - ${this.new_closed_type}`;
        }
      } else {
        return this.new_deal_status;
      }
      return null;
    },

    sortedPaymentLogs() {
      if (!this.dealData.pymt || !this.dealData.pymt.payment_logs) {
        return [];
      }

      return [...this.dealData.pymt.payment_logs].sort((a, b) => {
        // Sort by date in ascending order
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        // If dates are equal, sort by type in descending order
        if (a.type < b.type) return 1;
        if (a.type > b.type) return -1;

        return 0;
      });
    },

    there_is_no_main_applicant() {
      const result =
        this.dealData.int_custs.length > 0 &&
        !this.dealData.int_custs.some((el) => el.main_applicant);

      return result;
    },
  },

  mounted() {
    // console.log(filt)
    this.loadPageData();
  },

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
#offer_table tr:hover {
  cursor: pointer !important;
  td {
    background-color: rgb(77, 77, 77);
  }
}
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.active_summary {
  color: green;
}

.inactive_summary {
  color: red;
}
.td-right-border {
  border-right: 1px solid #333;
}

.side-nav {
  background-color: #111 !important;
  color: white !important;
  top: 100px !important;
  position: sticky !important;
  position: -webkit-sticky !important;
  min-width: 100px;
}

.note-contents {
  p {
    img {
      max-width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #444;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
